<template>
  <div class="app-container">
    <el-card class="box-card conter">
      <div class="title">组织登录</div>
      <el-form ref="loginForm" :model="form" :rules="rules">
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            spellcheck="false"
            autocomplete="on"
            placeholder="请输入邮箱(xxxx@xxx.com)"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            :type="pwdType"
            ref="pass"
            v-model="form.password"
            spellcheck="false"
            autocomplete="on"
            placeholder="请输入密码"
          ></el-input>
          <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span>
        </el-form-item>
        <el-form-item class="desc-wrapper" prop="key">
          <el-row :span="24" :gutter="17">
            <el-col :span="14">
              <el-checkbox v-model="form.key">
                我已阅读并接受
                <router-link
                  tag="span"
                  :to="{ path: 'agreement', query: { name: 'user' } }"
                  class="yellow"
                  >用户协议</router-link
                >&nbsp;和
                <router-link
                  tag="span"
                  :to="{ path: 'agreement', query: { name: 'policy' } }"
                  class="yellow"
                  >隐私政策</router-link
                >
              </el-checkbox>
            </el-col>
            <!-- <el-col :span="8" :style="{textAlign:'right'}" :offset="2">
            <router-link to="institutions" class="text-wrapper">组织用户登录</router-link>
          </el-col>-->
          </el-row>
        </el-form-item>
        <el-form-item class="submit-wrapper">
          <button
            type="button"
            class="submit"
            @click="submitForm('loginForm', $event)"
          >
            登录
          </button>
        </el-form-item>
        <el-form-item>
          <div class="item-wrapper">
            <div>
              <el-checkbox v-model="checked" style="color: #a0a0a0"
                >记住密码</el-checkbox
              >
            </div>
            <div>
              <router-link :to="{ name: 'registered' }" class="text-wrapper"
                >注册</router-link
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { validEmail, isPassWord } from "@/utils/validate";
import { setEncryption } from "@/mixins";
export default {
  mixins: [setEncryption],
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error("邮箱不符合规则"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (!isPassWord(value)) {
        callback(new Error("请输入至少6位"));
      } else {
        callback();
      }
    };
    const validatekey = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请勾选用户协议"));
      } else {
        callback();
      }
    };
    return {
      pwdType: "password",
      checked: false,
      form: {
        email: "",
        password: "",
        key: false,
      },
      rules: {
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
        key: [{ required: true, trigger: "blur", validator: validatekey }],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    submitForm(name, event) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          let form = { ...this.form };
          form.password = this.encryption(form.password);
          await this.$store.dispatch("organ_login", form);
          this.$router.push({ name: "personal" });
        }
      });
    },
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
      this.$nextTick(() => {
        this.$refs.pass.focus();
      });
    },
  },
  created() {
    this.$emit("customStyle", 0);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/elForm.scss";
.app-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: url("../../assets/image/login_bg.png") no-repeat;
  background-size: cover;
  color: #333;
  .conter {
    margin: 0 auto;
    width: 450px;
    height: 400px;
    /deep/ .el-card__body {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      .title {
        font-size: 25px;
        margin-bottom: 25px;
      }
    }
  }
}
</style>
